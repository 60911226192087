import React from 'react';
import '../../App.scss';

class CardReponseViolet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        if (this.props.isPortrait) {
            return (
                <div className="QuizVioletReponses">
                    <div className="QuizVioletReponsesContainer">
                        <div className="QuizVioletCardReponse">
                            <div className="QuizVioletReponseTitre">
                                IDÉE REÇUE
                            </div>
                            <div className="QuizVioletEnonce">
                                {this.props.card.idea}
                            </div>
                            <div className="QuizVioletReponse">
                                {this.props.card.facts}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="QuizVioletReponses">

                    {this.props.cards.map((card) => {
                        return (
                            <div className="QuizVioletReponsesContainer">
                                <div className="QuizVioletCardReponse">
                                    <div className="QuizVioletReponseTitre">
                                        IDÉES REÇUE
                                    </div>
                                    <div className="QuizVioletEnonce">
                                        {card.idea}
                                    </div>
                                    <div className="QuizVioletReponse">
                                        {card.facts}
                                    </div>
                                </div>
                            </div>)
                    })}
                </div>
            )
        }
    };

}

export default CardReponseViolet;

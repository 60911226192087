import React from 'react';
import '../../App.scss';
import PopUp from '../../commons/PopUp';
import DragAndDropVert from './DragAndDropVert';
import './QuizVert.scss';

class QuizVert extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleReady = this.handleReady.bind(this);
    }

    handleChange(arrayReponse) {
        this.props.onPageChange('', arrayReponse);
    }

    handleReady(ready, r) {
        this.setState({ ready: ready, reponses: r });
    }

    checkAnswer() {
        let arrayReponse = [];
        const listToDisplay = this.props.partieToDisplay === 1 ? 'family' : this.props.partieToDisplay === 2 ? 'rate' : 'behaviors';
        this.state.reponses.forEach((r, index) => {
            if (this.props.data.cards[index][listToDisplay] === r) {
                arrayReponse.push(true)
            } else {
                arrayReponse.push(false)
            }
        });
        this.handleChange(arrayReponse)
    }

    render() {
        //Affichage quizz uniquement
        const listToDisplay = this.props.partieToDisplay === 1 ? 'family' : this.props.partieToDisplay === 2 ? 'rate' : 'behaviors';
        const totalSteps = Object.keys(this.props.data).filter( (key) => {
            return key.includes('desc');
        }).length
        return (<div>
            <div className={this.props.showPopUp ? 'Show' : 'Hide'}>
                <PopUp onPageChange={this.handleChange}
                    color={'Vert'}
                    titre={this.props.partieToDisplay === 1 ? this.props.data.title : null}
                    texte={this.props.data['desc_' + this.props.partieToDisplay]}
                    infos={this.props.step + "/" + totalSteps}
                    ok={this.props.wordings["ui.btn.ok"]} />
            </div>
            <div className={'QuizVertContainer Mode-' + listToDisplay} >
                <DragAndDropVert onReadyChange={this.handleReady} data={this.props.data} partieToDisplay={this.props.partieToDisplay}></DragAndDropVert>
            </div>
            <button className={'BoutonValider BoutonVertValider ' + (this.state.ready ? 'Show' : 'Hide')} onClick={() => this.checkAnswer()}>
                {this.props.wordings['ui.tbn.validate']}
            </button>
            <div className="PageFooter PageVertFooter"></div>
        </div >)
    }
}

export default QuizVert;

import React from 'react';
import './QuizRose.scss';
import '../../App.scss';
import PopUp from '../../commons/PopUp';
import CardReponseRose from './CardReponseRose';


class QuizRose extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            answer: 0
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeAnwser = this.handleChangeAnwser.bind(this);
    }

    handleChange(r) {
        this.props.onPageChange(r, '');
    }

    handleChangeAnwser(i) {
        this.setState({ answer: i });
    }

    checkAnswer() {
        this.handleChange(this.props.data.questions[this.props.questionToDisplay - 1].rightAnswers[0] === this.state.answer)
    }

    render() {
        //Affichage PopUp + quizz en fond
        return (
            <div className="H-100">
                <div className={this.props.showPopUp ? 'Show' : 'Hide'}>
                    <PopUp onPageChange={this.handleChange}
                        color={'Rose'}
                        titre={this.props.data.title}
                        texte={this.props.data.desc}
                        infos={"1/1"}
                        ok={this.props.wordings["ui.btn.ok"]} />
                </div>
                <div className="QuizRoseContainer">
                    <div className="QuizRoseQuestion">
                        <div className="QuestionEnCours QuestionEnCoursRose">
                            {this.props.questionToDisplay}/{this.props.data.questions.length}
                        </div>
                        {this.props.data.questions[this.props.questionToDisplay - 1].question}
                    </div>
                    <div className="QuizSeparator"></div>
                    <CardReponseRose onTickChange={this.handleChangeAnwser} questionToDisplay={this.props.data.questions[this.props.questionToDisplay - 1]}></CardReponseRose>
                    <div className="Source">
                        {this.props.data.questions[this.props.questionToDisplay - 1].source}
                    </div>
                </div>
                <button className={'BoutonValider BoutonRoseValider ' + (this.state.answer > 0 ? 'Show' : 'Hide')} onClick={() => this.checkAnswer()}>
                    {this.props.wordings['ui.tbn.validate']}
                </button>
                <div className="PageFooter PageRoseFooter"></div>
            </div>
        )
    }
}

export default QuizRose;

import React from 'react';
import PopUp from '../../commons/PopUp';
import '../../App.scss';
import './QuizBleu.scss';
import CardReponseBleu from './CardReponseBleu';

class QuizBleu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reponses: [],
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeAnwser = this.handleChangeAnwser.bind(this);
    }

    handleChange(r) {
        this.props.onPageChange(r, '');
    }

    handleChangeAnwser(r) {
        this.setState({ reponses: r });
    }

    checkAnswer() {
        let a = this.props.data.questions[this.props.questionNumber - 1].rightAnswers.sort()
        let b = this.state.reponses.sort()
        let reponse = a.every((val, index) => val === b[index]) && a.length === b.length;

        this.handleChange(reponse)
    }

    render() {
        //Affichage PopUp + quizz en fond
        return (
            <div className="H-100">
                <div className={this.props.showPopUp ? 'Show' : 'Hide'}>
                    <PopUp onPageChange={this.handleChange}
                        color={'Bleu'}
                        titre={this.props.data.title}
                        texte={this.props.data.desc}
                        infos={"1/1"}
                        ok={this.props.wordings["ui.btn.ok"]} />
                </div>
                <div className="QuizBleuSpacer">
                    <CardReponseBleu onTickChange={this.handleChangeAnwser} questionNumber={this.props.questionNumber} questionToDisplay={this.props.data.questions[this.props.questionNumber - 1]}></CardReponseBleu>
                </div>
                <button className={'BoutonValider BoutonBleuValider ' + (this.state.reponses.length !== 0 ? 'Show' : 'Hide')} onClick={() => this.checkAnswer()}>
                    {this.props.wordings['ui.tbn.validate']}
                </button>
                <div className="PageFooter PageBleuFooter"></div>
            </div>
        )
    }
}

export default QuizBleu;

import React from 'react';
import '../../App.scss';

class CardReponseBleu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tick: []
        }
    }

    tick(i) {
        if (this.props.questionToDisplay.rightAnswers.length === 1) {
            this.state.tick = [];
            this.state.tick.push(i);
        } else {
            let index = this.state.tick.indexOf(i);
            if (index > -1) {
                this.state.tick.splice(index, 1);
            } else {
                this.state.tick.push(i)
            }
        }
        this.props.onTickChange(this.state.tick);
    }

    render() {
        return (<div className="QuizBleuContainer">
            <div className="QuizBleuQuestion">
                <div className="QuestionEnCours QuestionEnCoursBleu">
                    {this.props.questionNumber}/6
            </div>
                {this.props.questionToDisplay.question}
            </div>
            <div className="QuizBleuReponses">
                <div className="QuizBleuReponse">
                    <input checked={(this.props.questionToDisplay.rightAnswers.includes(1) && this.props.disabled) || this.state.tick.includes(1)} disabled={this.props.disabled} onChange={() => this.tick(1)} type="checkbox" id="repBleu1" name="repBleu1" value="1"></input>
                    <label htmlFor="repBleu1">{this.props.questionToDisplay.answer_1}</label>
                </div>
                <div className="QuizBleuReponse">
                    <input checked={(this.props.questionToDisplay.rightAnswers.includes(2) && this.props.disabled) || this.state.tick.includes(2)} disabled={this.props.disabled} onChange={() => this.tick(2)} type="checkbox" id="repBleu2" name="repBleu2" value="2"></input>
                    <label htmlFor="repBleu2">{this.props.questionToDisplay.answer_2}</label>
                </div>
                {!this.props.questionToDisplay.answer_3 ? null :
                    <div className="QuizBleuReponse">
                        <input checked={(this.props.questionToDisplay.rightAnswers.includes(3) && this.props.disabled) || this.state.tick.includes(3)} disabled={this.props.disabled} onChange={() => this.tick(3)} type="checkbox" id="repBleu3" name="repBleu3" value="3"></input>
                        <label htmlFor="repBleu3">{this.props.questionToDisplay.answer_3}</label>
                    </div>
                }
            </div>
        </div>
        );
    };

}

export default CardReponseBleu;

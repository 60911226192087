import React from 'react';
import '../App.scss';
import signature from '../assets/images/Signature-Image.png';
import Logo from '../assets/images/Logo.png';
import { getThemeColor } from './ThemeColor'
class End extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.props.onPageChange("", "");
    }

    render() {
        const logoEntrepriseSyle = {
            backgroundImage: 'url(' + this.props.version.logo + ')',
        };

        // Affichage Page Crédits
        return (
            <div className="EndScreen">
                <div className="BlocHautEnd">
                    <div className="Felicitations">{this.props.wordings['ui.end.congrats']}</div>
                    <div className="LogoContainer">
                        <div className="LogoEntreprise" style={logoEntrepriseSyle}></div>
                    </div>
                    <div className="Remerciement">{this.props.wordings['ui.end.thanks']}</div>
                </div>
                <div className="EndscreenFooter"
                     style={{backgroundColor: getThemeColor(this.props.quizContent)}}>
                    <div className="LogoSensiPlay">
                        <img src={Logo}/>
                    </div>
                    <div className="Memo">
                        {this.props.version.pdfLink && <><div dangerouslySetInnerHTML={{__html: this.props.wordings['ui.end.memoLabel']}}></div>
                            <div className="BtnDownload">
                            <a className="Link" href={this.props.version.pdfLink} download={this.props.wordings['ui.end.memoDownloadName'] + ".pdf"}>
                            <button >
                        {this.props.wordings['ui.btn.memo']}
                            <i className="Download"></i>
                            </button>
                            </a>
                        {this.props.version.video_clip_activated &&
                            <a className="Link" href={this.props.version.video_clip_url}>
                            <button >
                            video
                            </button>
                            </a>
                        }
                            </div></>}
                    </div>
                    <div className="SignatureContainer">
                        <img src={signature} className="JLO" alt="signature"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default End;

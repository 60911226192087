import React from 'react';
import '../../App.scss';

class CardReponseRose extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: 0
        }
    }

    tick(i) {
        this.setState({ selected: i });
        this.props.onTickChange(i);
    }

    render() {
        const reponse = this.props.questionToDisplay.rightAnswers[0];

        return (
            <div className="QuizRoseReponsesContainer">
                <div className="QuizRoseReponses">
                    <button disabled={this.props.disabled} onClick={() => this.tick(1)} className={this.state.selected === 1 || (reponse === 1 && this.props.disabled) ? 'Selected' : ''}>
                        {this.props.questionToDisplay.answer_1}
                    </button>
                    <button disabled={this.props.disabled} onClick={() => this.tick(2)} className={this.state.selected === 2 || (reponse === 2 && this.props.disabled) ? 'Selected' : ''}>
                        {this.props.questionToDisplay.answer_2}
                    </button >
                    <button disabled={this.props.disabled} onClick={() => this.tick(3)} className={this.state.selected === 3 || (reponse === 3 && this.props.disabled) ? 'Selected' : ''}>
                        {this.props.questionToDisplay.answer_3}
                    </button>

                </div>
                <div className="Precisions">
                    {this.props.questionToDisplay.precisions}
                </div>
            </div>
        );
    };

}

export default CardReponseRose;

import {EGAPRO_CONTENT, HANDICAP_CONTENT, SANTE_SECURITE_AU_TRAVAIL_CONTENT, HANDIPSY_CONTENT} from "../codes";

export const getThemeColor = function(quizContent) {
    switch (quizContent) {
        case HANDICAP_CONTENT :
            return "#653ceb";
        case EGAPRO_CONTENT :
            return "#f5be4f";
        case SANTE_SECURITE_AU_TRAVAIL_CONTENT :
            return "#4daab3";
        case HANDIPSY_CONTENT :
            return "#653ceb";
    }
}

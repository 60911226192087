/* eslint-disable */
// @ts-nocheck

// Add Google Analytics embed code
const script = document.createElement("script")
const GATrackingId = process.env.REACT_APP_GA_TRACKING_ID

script.async = true
script.src = `//googletagmanager.com/gtag/js?id=${GATrackingId}`
document.body.appendChild(script)

script.onload = () => {
    window.dataLayer = window.dataLayer || []

    function gtag() {
        dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', GATrackingId)

    const { location } = window
    const searchParams = new URLSearchParams(location.search)
    const company = searchParams.get("uid")

    if (location.pathname === '/app' && company) {
        gtag('set', 'user_properties', {
            Entreprise: company,
        })
    }
}

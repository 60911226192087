import React from 'react';
import '../../App.scss';
import PopUp from '../../commons/PopUp';
import DragAndDropViolet from './DragAndDropViolet';
import './QuizViolet.scss';


class QuizViolet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleReady = this.handleReady.bind(this);
    }

    handleChange(arrayReponse) {
        this.props.onPageChange('', arrayReponse);
    }

    handleReady(ready, r) {
        this.setState({ ready: ready, reponses: r });
    }

    checkAnswer() {
        let arrayReponse = [];
        this.state.reponses.forEach((r, index) => {
            if (this.props.data.cards[index].facts === r) {
                arrayReponse.push(true)
            } else {
                arrayReponse.push(false)
            }
        });
        this.handleChange(arrayReponse)
    }

    render() {
        //Affichage PopUp + quizz en fond
        return (
            <div className="H-100">
                <div className={this.props.showPopUp ? 'Show' : 'Hide'}>
                    <PopUp onPageChange={this.handleChange}
                        color={'Violet'}
                        titre={this.props.data.title}
                        texte={this.props.data.desc}
                        infos={"1/1"}
                        ok={this.props.wordings["ui.btn.ok"]} />
                </div>
                <div className="QuizVioletContainer">
                    <DragAndDropViolet onReadyChange={this.handleReady} data={this.props.data}></DragAndDropViolet>
                </div>
                <button className={'BoutonValider BoutonVioletValider ' + (this.state.ready ? 'Show' : 'Hide')} onClick={() => this.checkAnswer()}>
                    {this.props.wordings['ui.tbn.validate']}
                </button>
                <div className="PageFooter PageVioletFooter"></div>
            </div>
        )
    }
}

export default QuizViolet;

import React from 'react';
import '../../App.scss';
import dragIcon from '../../assets/images/drag-icon.png';
import FlecheDroite from '../../assets/images/Fleche-Droite-Violet.png';
import FlecheGauche from '../../assets/images/Fleche-Gauche-Violet.png';
import { changeTouch, cloneElementToDrag, disableDragZones, enableDragZones, prepareElementsForDrag, removeClones, shuffle } from '../../commons/Utils';
import './QuizViolet.scss';

class DragAndDropViolet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cardResponse: ["", "", "", ""],
            position: 0,
            shuffledCards: [],
            dragInfos: [0, 0, 0, 'none']
        }

        this.state.shuffledCards = shuffle(this.props.data.cards);
    }

    prev() {
        this.setState({ position: (this.state.position + this.props.data.cards.length - 1) % this.props.data.cards.length });
    }

    next() {
        this.setState({ position: (this.state.position + 1) % this.props.data.cards.length });
    }

    onTouchStart = (ev, id) => {
        enableDragZones('QuizVioletCardReponse');

        var changedTouch = ev.changedTouches[0];

        var source = ev.target;
        while (source.className.indexOf('QuizVioletCard') === -1) {
            source = source.parentElement;
        }

        cloneElementToDrag(source, id);

        this.setState({
            dragInfos: [
                id,
                changedTouch.clientX - (source.offsetWidth),
                changedTouch.clientY - (source.offsetHeight * 0.5),
                'flex'
            ]
        });

        this.onDragStart(ev, id);
    }

    onDragStart = (ev, id) => {
        enableDragZones('QuizVioletCardReponse');
        prepareElementsForDrag(ev, id);
    }

    onDragOver = (ev, id) => {
        if (ev && ev.changedTouches) {
            var elem = document.getElementById('Copy-' + id);
            if (elem) {
                elem.style.display = 'flex';
            }

            var changedTouch = ev.changedTouches[0];
            this.setState({
                dragInfos: [
                    id,
                    changedTouch.clientX - (elem.offsetWidth),
                    changedTouch.clientY - (elem.offsetHeight * 0.5)
                ]
            });
        } else {
            ev.preventDefault();
        }
    }

    onDrop = (ev, index) => {
        let id;
        let takeIndex = false;
        if (ev.dataTransfer) {
            id = ev.dataTransfer.getData("text");
            takeIndex = true
        } else {
            id = ev.currentTarget.id;
        }

        this.drop(id, index, takeIndex);
        ev.preventDefault();
    }

    drop = (id, index, takeIndex, invalidDrop) => {
        if (!invalidDrop) {
            let position = index ? index : this.state.position;
            let testReponse = [];
            let questions = this.props.data.cards.filter(q => {
                if (q.id.toString() === id.toString()) {
                    this.state.cardResponse[position] = q.facts;
                    testReponse = this.state.cardResponse.map(e => e !== "");
                    if (!testReponse.includes(false)) {
                        this.props.onReadyChange(true, this.state.cardResponse);
                    }
                }
                return q;
            });

            this.setState({
                ...this.state,
                questions,
                dragInfos: [0, 0, 0, 'none']
            });
        }

        var elem = document.getElementById('Copy-' + id);
        if (elem) {
            elem.innerHTML = '';
        }

        this.setState({
            dragInfos: [0, 0, 0, 'none']
        });

        removeClones();
    }

    reset(answerPosition) {
        var newReponses = [...this.state.cardResponse];
        newReponses[answerPosition] = '';
        this.setState({
            cardResponse: newReponses
        });
        this.props.onReadyChange(false, '');
    }

    render() {
        return (
            <div classfamily="container-drag">
                <div className="QuizVioletTitre">
                    {this.props.data.subtitle_1}
                </div>
                <div className="QuizVioletCards" classfamily="droppable">
                    {this.state.shuffledCards.map((card, index) => {
                        const draggable = this.state.cardResponse.includes(card.facts) ? false : true;
                        const copyStyle = {
                            position: 'absolute', zIndex: 99, display: this.state.dragInfos[3], top: this.state.dragInfos[2], left: this.state.dragInfos[1]
                        }
                        return <div key={card.id}>
                            <div className={this.state.cardResponse.includes(card.facts) ? 'CardVioletGrise' : 'QuizVioletCard'} id={'Card-' + card.id}
                                onDragStart={(e) => draggable ? this.onDragStart(e, card.id) : null}
                                onDragEnd={(e) => disableDragZones('QuizVioletCardReponse')}
                                onTouchStart={(e) => draggable ? this.onTouchStart(e, card.id) : null}
                                onTouchMove={(e) => draggable ? this.onDragOver(e, card.id) : null}
                                onTouchEnd={(e) => { if (draggable) { disableDragZones('QuizVioletCardReponse'); changeTouch(e, card.id, this.drop) } }}
                                draggable={draggable} classfamily="draggable">
                                {this.state.cardResponse.includes(card.facts) ? '' : card.facts}
                                <div className="CardDrag" className={this.state.cardResponse.includes(card.facts) ? 'Hide' : 'CardDrag'}><img className="indexImage" draggable="false" src={dragIcon} alt='card1' /></div>
                            </div>
                            <div id={'Copy-' + card.id} style={this.state.dragInfos[0] === card.id ? copyStyle : null}></div>
                        </div>
                    })}
                </div>

                <div className="SourceViolet">
                    {this.props.data.sources}
                </div>

                <div className="QuizSeparator"></div>

                <div className="QuizVioletReponses">
                    <div className="ReponsePrevContainer">
                        <button className="ReponsePrev" onClick={() => this.prev()}>
                            <img src={FlecheGauche} alt='gauche' />
                        </button>
                    </div>
                    <div className="QuizVioletReponsesContainer">
                        {this.props.data.cards.map((card, index) => {
                            const cardStyle = {
                                marginLeft: this.state.position === 0 || index > 0 ? '0' : (-1 * (this.state.position) * 100) + '%',
                            };
                            return (<div className="QuizVioletCardReponse" id={'dropCard-' + index} key={'reponse-' + index} style={cardStyle}
                                onDragOver={(e) => this.onDragOver(e, index)}
                                onDrop={(e) => this.onDrop(e, index)}
                                onClick={(e) => this.reset(index)}>
                                <div className="QuizVioletReponseTitre">
                                    {this.props.data.subtitle_2}
                                </div>
                                <div className="QuizVioletEnonce">
                                    {card.idea}
                                </div>
                                <div className={'QuizVioletReponse ' + (!this.state.cardResponse[index] ? ' DragActive' : ' DragDone')}
                                    classfamily="droppable">
                                    {this.state.cardResponse[index] ? this.state.cardResponse[index] : <span>+</span>}
                                </div>
                            </div>
                            );
                        })}
                    </div>
                    <div className="ReponseNextContainer">
                        <button className="ReponseNext" onClick={() => this.next()}>
                            <img src={FlecheDroite} alt='droite' />
                        </button>
                    </div>
                </div>

            </div >
        );
    }
}

export default DragAndDropViolet;